<template>
    <div class="aboutOurs">
        <div class="marginAuto" style="width:96%;margin-top:20px;" v-html="about.content"></div>
    </div>
</template>
<script>
import { commonPrivacy } from '@/utils/Api/commonList'
export default {
    name:'aboutOurs',
    data(){
        return{
            about:{}
        }
    },
    created(){
        this.initial()
    },
    methods:{
        async initial(){
            let cbk = await commonPrivacy({type: 8})
            this.about = cbk
        }
    }
}
</script>
<style lang="scss" scoped>
.aboutOurs{

}
</style>